import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { desktop, mobile } from '../../styles/media'
import translate from '../../helper/translate'
import { StyledMessage } from './Message'
import unique from '../../helper/unique'
import ProFeature from '../general/ProFeature'
import { StyledInputContainer } from './Input'
import { StyledCheckboxLabel } from './Checkbox'

const Field = ({ children, className, column, disabled, fieldOption, label, labelActive, labelLink, longInput, optional, pro, row, t }) => {
	const fieldId = unique('field')

	const handleFocus = () => {
		const idEl = document.getElementById(fieldId)

		if (idEl) {
			let el

			if (idEl.querySelector('input')) {
				el = idEl.querySelector('input')
			} else {
				el = idEl
			}

			if (typeof el.select === 'function') {
				el.select()
			}
		}
	}

	return (
		<StyledField className={className} column={column} disabled={disabled} longInput={longInput}>
			{label !== '' && (
				<Label
					active={labelActive}
					fieldOption={fieldOption}
				>
					<LabelInner onClick={handleFocus}>
						{label}
					</LabelInner>
					{optional && (
						<span>
							{`(${t('general.optional')})`}
						</span>
					)}
					{pro && <ProFeature reference={pro} />}
					{fieldOption && <FieldOption>{fieldOption}</FieldOption>}
					{labelLink && <LabelLink>{labelLink}</LabelLink>}
				</Label>
			)}
			<FieldInner row={row}>
				{React.Children.map(children, (child) => {
					if (child && child.type) {
						return React.cloneElement(child, {
							id: fieldId
						})
					}

					return child
				})}
			</FieldInner>
		</StyledField>
	)
}

Field.propTypes = {
	className: PropTypes.string,
	children: PropTypes.any.isRequired,
	column: PropTypes.bool,
	disabled: PropTypes.bool,
	fieldOption: PropTypes.any,
	label: PropTypes.any,
	labelActive: PropTypes.bool,
	labelLink: PropTypes.any,
	longInput: PropTypes.bool,
	optional: PropTypes.bool,
	pro: PropTypes.string,
	row: PropTypes.bool,
	t: PropTypes.func.isRequired
}

Field.defaultProps = {
	className: null,
	column: false,
	disabled: false,
	fieldOption: null,
	label: '',
	labelActive: false,
	labelLink: null,
	longInput: false,
	optional: false,
	pro: null,
	row: false
}

const FieldInner = styled.div`
	display: flex;
	flex-direction: column;

	> p {
		color: ${props => props.theme.textLight};
		font-size: 0.8em;
		line-height: 1.2;
		margin-top: .75em;
		margin-bottom: 0;
		width: 100%;
	}

	> a {
		text-decoration: none;
	}

	> p + ${StyledCheckboxLabel}:last-child {
		margin-top: 1rem;
		margin-bottom: 0;
	}

	${desktop`
		> p {
			width: 300px;
			max-width: 100%;
		}
	`}

	${props => props.row && `
		gap: 1rem;
		flex-direction: row;
	`}
`

export const StyledField = styled.div`
	margin-bottom: 1.5rem;

	${StyledMessage} {
		margin: 0.5rem 0 0 0;
		width: 300px;

		${mobile`
			width: 100%;
		`}
	}

	${props => props.column ? `
		display: flex;
		flex-direction: column !important;
		width: 100%;

		label {
			margin-right: 0;
		}

		${FieldInner}, ${StyledInputContainer}, input, textarea {
			max-width: none;
			width: 100%;
		}
	` : ''}

	${props => props.disabled ? `
		opacity: 0.5;
		pointer-events: none;
	` : ''}

	${props => props.longInput ? `
		input {
			width: 500px !important;
		}
	` : ''}
`

const Label = styled.label`
	align-items: center;
	display: flex;

	color: ${props => props.theme.id === 'dark' ? '#AAA' : props.theme.textSecondary};
	margin-bottom: 0;
	padding-bottom: 0.5rem;
	width: fit-content;

	> span {
		color: ${props => props.theme.textVeryLight};
		margin-left: .25rem;
	}

	> svg {
		margin-right: 0.25rem;
	}

	${props => props.active && `
		text-decoration: underline;
		text-decoration-color: ${props.theme.primary};
		text-decoration-thickness: 2px;
	`}

	${desktop`
		${props => props.fieldOption && `
			width: 300px;
		`}
	`}

	${mobile`
		${props => props.fieldOption && `
			width: 100%;
		`}
	`}
`

const LabelInner = styled.div`

`

const FieldOption = styled.div`
	margin-left: auto;

	a {
		font-size: 0.9rem;
	}

	label {
		margin: 0;
	}

	label input {
		margin-right: 0.35rem;
	}
`

const LabelLink = styled.div`
	margin-left: 0.35rem;

	a {
		font-size: 0.8rem;
		font-weight: 600;
		text-transform: uppercase;
	}
`

const enhance = translate('general')

export default enhance(Field)
