import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import Avatar from '../../components/user/Avatar'
import Field, { StyledField } from '../../components/form/Field'
import Textarea from '../../components/form/Textarea'
import Button, { StyledButton } from '../../components/button/Button'
import Message, { StyledMessage } from '../../components/form/Message'
import Link from '../../components/router/Link'
import { StyledInputContainer } from '../../components/form/Input'
import { patch, post } from '../../helper/api'
import translate from '../../helper/translate'
import { sortComments } from '../../helper/sort'
import { mobile, VIEWPORT_MOBILE } from '../../styles/media'
import { getYouTubeId, isLink } from '../../helper/string'
import { setDeck } from '../../state/actions/deckbuilder'
import { showToast } from '../../state/actions/app'

class DeckCommentForm extends Component {
	static propTypes = {
		authToken: PropTypes.string,
		autoFocus: PropTypes.bool,
		canEdit: PropTypes.bool,
		comment: PropTypes.string,
		commentId: PropTypes.number,
		comments: PropTypes.array,
		commentOnChange: PropTypes.func,
		commentsOnChange: PropTypes.func,
		deck: PropTypes.object,
		deckAuthorUsername: PropTypes.string.isRequired,
		deckSlug: PropTypes.string.isRequired,
		onEditLastComment: PropTypes.func,
		emailIsVerified: PropTypes.bool.isRequired,
		mentionUsername: PropTypes.string,
		onCancel: PropTypes.func,
		setDeck: PropTypes.func.isRequired,
		settings: PropTypes.object.isRequired,
		showToast: PropTypes.func.isRequired,
		sortBy: PropTypes.string.isRequired,
		t: PropTypes.func.isRequired,
		threadId: PropTypes.number,
		userAvatar: PropTypes.string.isRequired,
		viewport: PropTypes.string.isRequired,
		votes: PropTypes.number
	}

	static defaultProps = {
		authToken: null,
		autoFocus: false,
		canEdit: false,
		comment: '',
		commentId: null,
		commentLanguage: null,
		comments: [],
		commentOnChange: null,
		commentsOnChange: null,
		deck: null,
		mentionUsername: null,
		onCancel: null,
		onEditLastComment: () => {},
		threadId: null,
		votes: 0
	}

	constructor(props) {
		super(props)

		this.state = {
			loading: false,
			comment: this.props.mentionUsername ? `@${this.props.mentionUsername} ` : this.props.comment,
			fieldErrors: {},
			focused: !!this.props.comment
		}

		this.textareaRef = React.createRef()
	}

	componentDidMount() {
		const { autoFocus } = this.props

		if (autoFocus) {
			const el = this.textareaRef.current
			el.focus()
			el.selectionStart = el.selectionEnd = el.value.length
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.deckSlug !== this.props.deckSlug) {
			this.setState({
				fieldErrors: {},
				focused: false
			})
		}
	}

	componentWillUnmount() {
		if (this.req) {
			this.req.abort()
		}
	}

	submit = () => {
		const { canEdit, comments, commentsOnChange, deck, deckSlug, setDeck, showToast, sortBy, t, threadId } = this.props
		const { comment, language } = this.state

		if (canEdit && isLink(comment) && comment.includes('youtu')) {
			this.setState({
				comment: ''
			}, () => {
				setDeck({
					...deck,
					youtubeVideoId: getYouTubeId(comment)
				}, ['youtubeVideoId'])

				showToast('info', t('general.deckYoutubeVideoAttached'))
			})

			return
		}

		this.setState({
			loading: true
		})

		this.req = post(`decks/${deckSlug}/comment`, {
			comment,
			language,
			threadId
		}, (data) => {
			this.setState(({
				loading: false,
				comment: '',
				fieldErrors: {},
				focused: false
			}), () => {
				if (commentsOnChange) {
					if (threadId) {
						const thread = comments.find(item => item.id === threadId)

						commentsOnChange(sortComments([
							...comments.filter(item => item.id !== threadId),
							{
								...thread,
								repliesCount: thread.repliesCount + 1,
								replies: [
									...(thread.replies || []),
									data
								]
							}
						], sortBy))
					} else {
						commentsOnChange([
							data,
							...comments
						])
					}
				}
			})
		}, (status, fieldErrors) => {
			this.setState({
				fieldErrors,
				loading: false
			})
		})
	}

	save = () => {
		const { commentId, commentOnChange } = this.props
		const { comment, language } = this.state

		this.setState({
			loading: true
		})

		this.req = patch(`comments/${commentId}`, {
			comment,
			language
		}, () => {
			this.setState(({
				loading: false,
				language,
				comment: '',
				fieldErrors: {},
				focused: false
			}), () => {
				commentOnChange(comment, language)
			})
		}, (status, fieldErrors) => {
			this.setState({
				fieldErrors,
				loading: false
			})
		})
	}

	cancel = () => {
		const { onCancel } = this.props

		if (this.req) {
			this.req.abort()
		}

		this.setState({
			comment: '',
			fieldErrors: {},
			focused: false,
			loading: false
		}, () => {
			if (onCancel) {
				onCancel()
			}
		})
	}

	handleKeyDown = (event) => {
		const { onEditLastComment } = this.props

		if (event.key === 'ArrowUp') {
			event.preventDefault()
			onEditLastComment()
		} else if (event.key === 'Escape') {
			event.preventDefault()
			this.cancel()
		}
	}

	render() {
		const { authToken, emailIsVerified, onEditLastComment, t, threadId, userAvatar, viewport, votes } = this.props
		const { loading, comment, fieldErrors, focused } = this.state

		return (
			<>
				<CommentForm replying={!!threadId}>
					{!this.props.comment && <Avatar avatar={userAvatar || 'avatar-male'} onClick={() => this.textareaRef.current.focus()} width={!!threadId ? 25 : 40} />}
					<Field>
						<Textarea
							value={comment}
							onChange={comment => this.setState({ comment })}
							onFocus={() => this.setState({ focused: true })}
							onKeyDown={this.handleKeyDown}
							minRows={focused ? 3 : 1}
							disabled={loading}
							inputRef={this.textareaRef}
							placeholder={threadId ? t('general.commentReplyPlaceholder') : t('general.commentPlaceholder')}
							errors={fieldErrors.comment}
						/>
					</Field>
				</CommentForm>

				{focused && (
					<CommentFormInfo replying={!!threadId} editing={!!this.props.comment}>
						{authToken && !emailIsVerified && (
							<Message warning light>{t('general.pleaseVerifyEmailToComment')}</Message>
						)}
						{authToken && emailIsVerified && !threadId && !this.props.comment && !fieldErrors.comment && (
							<Message primary light>{t('general.communityGuidelinesHint', { guidelines: <Link to="/docs/community/community-guidelines">{t('general.communityGuidelines')}</Link> })}</Message>
						)}
						{!authToken && (
							<Message warning light>{t('general.pleaseLoginToComment', { login: <Link to="/login">{t('general.pleaseLoginToComment2')}</Link> })}</Message>
						)}
						{votes > 0 && (
							<Message warning light>{t('general.commentVotesWillReset')}</Message>
						)}
					</CommentFormInfo>
				)}

				{focused && authToken && emailIsVerified && (
					<CommentFormButtons replying={!!threadId} editing={!!this.props.comment}>
						<Button
							secondary
							onClick={this.cancel}
							small={viewport === VIEWPORT_MOBILE}
						>
							{t('general.cancel')}
						</Button>
						<Button
							onClick={this.props.comment ? this.save : this.submit}
							loading={loading}
							small={viewport === VIEWPORT_MOBILE}
							disabled={loading || comment.length < 2}
						>
							{this.props.comment && !threadId && t('general.save')}
							{!this.props.comment && !threadId && t('general.commentSubmit')}
							{threadId && t('general.reply')}
						</Button>
					</CommentFormButtons>
				)}
			</>
		)
	}
}

const CommentForm = styled.div`
	display: flex;

	img, svg {
		border-radius: 50%;
		height: 40px;
		width: 40px;
		min-width: 40px;
		margin-right: 1rem;
	}

	${StyledField} {
		margin-bottom: 0;
		width: 100%;
	}

	${StyledInputContainer}, textarea {
		width: 100%;
		max-width: none;

		> ${StyledMessage} {
			margin-bottom: 0;
		}
	}

	${props => props.replying && `
		margin-top: 0.5rem;

		img, svg {
			height: 25px;
			width: 25px;
			min-width: 25px;
		}
	`}
`

const CommentFormInfo = styled.div`
	${StyledMessage} {
		font-size: 0.9rem;
		margin-top: 1rem !important;
		padding: 0.5rem 0.75rem;

		${props => !props.editing && `
			margin-left: calc(40px + 1rem);
			margin-bottom: 1rem !important;
			width: calc(100% - 40px - 1rem) !important;
		`}

		${props => props.editing && `
			margin-bottom: 0 !important;
		`}

		${props => props.replying && `
			margin-left: calc(24px + 1rem);
			width: calc(100% - 24px - 1rem) !important;
		`}

		${mobile`
			font-size: 0.8rem;
		`}
	}
`

const CommentFormButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;

	${props => !props.editing && !props.replying && `
		margin-left: calc(40px + 1rem);
	`}

	${props => !props.editing && props.replying && `
		margin-left: calc(24px + 1rem);
	`}

	${StyledButton} + ${StyledButton} {
		margin-left: 1rem;
	}

	${StyledInputContainer} {
		margin-right: 0.5rem;
		width: 120px;
		min-width: 120px;
	}

	${mobile`
		align-items: flex-end;
		margin-top: 0.5rem;

		${StyledButton} + ${StyledButton} {
			margin-left: 0.5rem;
		}
	`}
`

const enhance = compose(
	connect(state => ({
		authToken: state.persist.authToken,
		deck: state.deckbuilder.deck,
		emailIsVerified: state.persist.user.emailIsVerified,
		settings: state.persist.user.settings,
		userAvatar: state.persist.user.avatar,
		viewport: state.app.viewport
	}), dispatch => bindActionCreators({
		setDeck,
		showToast
	}, dispatch)),
	translate('general')
)

export default enhance(DeckCommentForm)
